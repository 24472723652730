<template>
    <div class="page-metope">
        <main-banner
            page-position="制作商城"
            page-title="用设计的力量，去支援疫情"
            router-name="前往图文制作"
            router-path="/media">
            <!-- 搜索框 -->
            <template #search>
                <search-component
                    :search-value="search"
                    :selected-value="select_type"
                    :select-options="filterType"
                    @handleSearch="arg => handleFun(arg.search,arg.type,select_style)">
                    <!-- 快捷选项 -->
                    <template #searchFast>
                        <ul class="search-fast">
                            <li>
                                大家都在搜：
                            </li>
                            <li v-for="item in hotWords"
                                :key="item.id">
                                <a href="javascript:;"
                                    @click="handleFast(item)">
                                    {{item}}
                                </a>
                            </li>
                        </ul>
                    </template>
                </search-component>
            </template>
        </main-banner>
        <div class="container page-metope-content">
            <!-- filter -->
            <filter-list
                :data-list="filterType"
                :selected-filter="select_type"
                @handleFilter="arg => handleFun(search,arg,select_style)">
            </filter-list>
            <filter-list
                v-if="filterStyle.length>0"
                :data-list="filterStyle"
                :selected-filter="select_style"
                @handleFilter="arg => handleFun(search,select_type, arg)"
                props-class="page-metope-filter">
            </filter-list>
            <!-- 热门 -->
            <main-hot
                hot-title="热门定制"
                list-router="/metope/list"
                :goods-list="metopeList">
                <template #goodsItem="{ item }">
                    <goods-item
                        router-path="metopeDetail"
                        :goods-item="item">
                    </goods-item>
                </template>
            </main-hot>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from "vuex";

    export default {
        data() {
            let search = sessionStorage.getItem('search') || '',
                select_type = sessionStorage.getItem('type') || '0',
                select_style = sessionStorage.getItem('style') || '0';
            return {
                search,
                select_type,
                select_style
            }
        },
        computed: mapState({
            metopeList: state => state.metope.metopeList,
            hotWords: state => state.metope.hotWords,
            filterStyle: state => state.metope.filterStyle,
            filterType: state => state.metope.filterType
        }),
        components: {
            searchComponent: () => import("@/components/imageMall/searchComponent"),
            mainBanner: () => import("@/components/makingMall/mainBanner"),
            filterList: () => import("@/components/makingMall/filterList"),
            mainHot: () => import("@/components/imageMall/mainHot"),
            goodsItem: () => import("@/components/makingMall/goodsItem")
        },
        methods: {
            ...mapMutations([
                'setMetopeList',
                'setMetopeFilters'
            ]),
            ...mapActions([
                'getPageMetope',
                'getMetopeFilters'
            ]),
            handleFun(search, type, style){
                sessionStorage.setItem('search', search);
                sessionStorage.setItem('type', type);
                sessionStorage.setItem('style', style);
                this.search = search;
                this.select_type = type;
                this.select_style = style;
                this.getMetopeFilters(type);
                this.getPageMetope({
                    search,
                    type,
                    style
                })
            },
            handleFast(val){ // 快捷搜索处理
                sessionStorage.setItem('search', val);
                this.search = val;
            },
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.handleFun(vm.search, vm.select_type, vm.select_style);
            })
        },
        beforeRouteLeave (to, from, next) {
            sessionStorage.removeItem('search');
            sessionStorage.removeItem('type');
            sessionStorage.removeItem('style');
            this.setMetopeList([]);
            this.setMetopeFilters({})
            next();
        }
    }
</script>

<style scoped>
.page-metope{
    --color: #1f3eaa;
    margin-bottom: 50px;
}
.page-metope-content{
    padding-top: 50px;
}
.page-metope-filter{
    margin-top: 20px;
}
.page-metope-filter >>> li  a{
    color: #1f3eaaa1;
}
/* search */
.search-fast{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.search-fast > li + li{
    margin-left: 10px;
}
.search-fast > li,
.search-fast a{
    color: #fff;
}
.search-fast a:hover{
    color: #8d9afc;
}
</style>